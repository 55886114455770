<template>
    <div class="grid">
      <loading
        :active="isLoadingModel"
        :can-cancel="true"
        color="#274461"
        :is-full-page="fullPage"
      ></loading>
      <Toast />
      <div class="col-12 sticky">
        <div id="custom_card" class="card">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3"
              >
                <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                  Ticket Status 
                </p>
              </div>
            </div>
            <div class="col-12 md:col-6">
              <div
                class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3"
              >
                <Button
                  iconPos="right"
                  label="Add"
                  icon="pi pi-plus"
                  v-tooltip.bottom="'Add'"
                  @click="openNew"
                  size="small"
                  style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="card" id="custom_card">
          <div class="grid p-fluid">
            <div class="col-12 md:col-12">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <span class="p-float-label">
                    <InputText
                      format="text"
                      v-model="search_filter"
                      @input="search(1)"
                    >
                    </InputText>
                    <label>Search Status</label>
                  </span>
                </div>
                <div class="field col-12 md:col-2" v-if="user_key=='Super Admin'">
                <span class="p-float-label">
                  <Dropdown
                    id="client_dropdown"
                    v-model="client_selected"
                    :options="client_dropdown"
                    :value="client_dropdown"
                    optionLabel="name"
                    filter
                    v-on:change="search(1)"
                  ></Dropdown>
                  <label for="Client">Client</label>
                </span>
              </div>
                <div class="field col-2 md:col-2">
                
                  <Button
                    icon="pi pi-refresh"
                    v-tooltip.bottom="'Refresh'"
                    @click="search(2)"
                    class="p-button-danger"
                    style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;"
                  />
                </div>
              </div>
            </div>
          </div>
  
          <DataTable
            :loading="loading"
            ref="dt"
            :lazy="true"
            :totalRecords="totalRecords"
            :paginator="true"
            :value="products"
            v-model:selection="selectedProducts"
            :dataKey="columns[0]"
            :rows="limit"
            :filters="filters"
            @page="onPage($event)"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[5, 10, 25, totalRecords]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            responsiveLayout="scroll"
            style="font-size: 12px"
            :rowHover="true"
            showGridlines
          >
            <template #empty>
              <b style="text-align: center">No records found.</b>
            </template>
            <template #header>
              
              <div style="text-align: right; font-weight: bold">
                <span>Total Status:</span>{{ totalRecords }}
              </div>
            </template>
            <Column header="Status" style="min-width: 5rem ;text-align: center;" :field="columns[14]" >
            <template #body="{ data }"> 
              <span v-if="data.status=='Active' ||data.status=='active'"><badge :value=data.status severity="success"></badge></span>
            <span v-else-if="data.status=='InActive' ||data.status=='In Active'"><badge :value=data.status severity="warning"></badge></span>
            <span v-else-if="data.status=='Delete' ||data.status=='Deleted'"><badge :value=data.status severity="danger"></badge></span>
            <span v-else>-</span>
            </template>
          </Column>
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
           
            <Column header="Name" style="min-width: 5rem">
              <template #body="{ data }">
                {{ data.name }}
              </template>
            </Column>
            
            <Column header="Action" headerStyle="min-width:10rem;">
              <template #body="slotProps">
                <Button
                
                  icon="pi pi-pencil"
                  title="Edit Status"
                  v-tooltip.bottom="'Edit'"
                  class="p-button-rounded p-button-help mr-2"
                  @click="editProduct(slotProps.data)"
                  style="
                      box-shadow: 0 2px 6px #ffc107;
                      border-color: #ffc107 !important;
                      background-color: #ffc107 !important;
                    "
                />
                <Button
                  icon="pi pi-trash"
                  v-tooltip.bottom="'Delete'"
                  class="p-button-rounded p-button-warning mt-2"
                  @click="confirmDeleteProduct(slotProps.data)"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;"
                />
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
  
      <Dialog
        v-model:visible="productDialog"
        :breakpoints="{ '960px': '75vw' }"
        :style="{ width: '45vw' }"
        header="Status"
        :modal="true"
        position="top"
      >
        <div class="col-12">
          <div class="p-fluid formgrid grid" style="margin-top: 2%">
            <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                  <InputText
                    id="ticket_title"
                    type="text"
                    v-model="product.name"
                  />
                  <label for="ticket_title">Status Name</label>
                </span>
              </div>
              <div class="field col-12 md:col-6" style="margin-top: 2%">
                <span class="p-float-label">
                    <Dropdown 
                    id="status" 
                    v-model="status_selected" 
                    :options="status_dropdownItems" 
                    optionLabel="name" 
                    filter
                    :disabled="!product._id"
                    :class="{ 'p-invalid': submitted && !status_dropdownItem }">
                    </Dropdown>
                  <label for="ticket_title">Status</label>
                </span>
              </div>
          </div>
        </div>
  
        <template #footer>
          <Button
            label="Save"
            @click="save_status"
            icon="pi pi-check"
            class="p-button-success"
          />
        </template>
      </Dialog>
  
      <Dialog
            v-model:visible="deleteProductDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
          >
            <div class="flex align-items-center justify-content-center">
              <i
                class="pi pi-exclamation-triangle mr-3"
                style="font-size: 2rem"
              />
              <span v-if="product"
                >Are you sure you want to delete <b>{{ product.name }}</b
                >?</span
              >
            </div>

            <div class="col-12">
                <div class="p-fluid formgrid grid" style="margin-top: 2%">
                    <div class="field col-12 md:col-12" style="margin-top: 2%">
                    <span class="p-float-label">
                        <Textarea
                        id="Remark"
                        type="text"
                        v-model="product.remark"
                        />
                        <label for="Remark">Remark</label>
                    </span>
                    </div>
                </div>
            </div>

            <template #footer>
              <Button
                label="No"
                icon="pi pi-times"
                class="p-button-text"
                @click="deleteProductDialog = false"
              />
              <Button
                label="Yes"
                icon="pi pi-check"
                class="p-button-text"
                @click="deletestatus"
              />
            </template>
          </Dialog>
    </div>
  </template>
    <script>
  import { FilterMatchMode } from "primevue/api";
  import ProductService from "../service/ProductService";
  
  import apis from "@/apis";
  import axios from "axios";
  export default {
    data() {
      return {
        client_dropdown:[],
      client_selected:"",
      clientid:"",

        status_dropdownItems:[
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
        ],
        status_selected:"",
        status_id:"",

          search_filter:"",
        user_key: "",
        page_no: 1,
        isLoading: false,
        isLoadingModel: false,
        loading: false,
        fullPage: true,
        products: null,
        productDialog: false,
        deleteProductDialog: false,
        deleteProductsDialog: false,
        lazyParams: {},
        product: {},
        selectedProducts: null,
        filters: {},
        offset: 1,
        limit: 10,
        columns: [],
        pageno: 1,
        submitted: false,
        totalRecords: 0,
        id: "",
      };
    },
    productService: null,
    async created() {
      this.productService = new ProductService();
      this.initFilters();
      this.user_key = localStorage.getItem("user_type");
      await this.getClientMaster();
    },
    async mounted() {
      this.id = this.$route.params.id;
      this.lazyParams = {
        first: 0,
        rows: this.limit,
        sortField: null,
        sortOrder: null,
        filters: this.filters,
      };
      if (this.totalRecords == 0) {
        await this.get_count();
      }
      await this.get_list();
    },
    watch: {
      async $route() {
        this.products = [];
        this.columns = [];
        this.totalRecords = 0;
        this.id = this.$route.params.id;
        if (this.id) {
          await this.get_count();
          this.get_list();
        }
      },
    },
    methods: {
        async getClientMaster() {
      var data = {
          limit: 10000,
          page_no: 1,
          count: false,
      };
     
      var promise = apis.getclientist(data);
      promise.then((response) => {
       this.client_dropdown = response.data.data;
      });
    },
        save_status()
        {   this.status_id="";
            this.status_id=this.status_selected.name;
            var name_regex = /^\s*$/;

            if (!this.product.name) 
            {        
              this.$toast.add({
                  severity: "error",
                  summary: "error",
                  detail: "Please Enter Status Name",
                  life: 3000,
              });
              return false;
            }

            if (this.product.name) 
            {   
              if (name_regex.test(this.product.name)) 
              {
                  this.$toast.add({
                  severity: "error",
                  summary: "error",
                  detail: "Please Enter Valid Status Name",
                  life: 3000,
                });
                return false;
              }                
            }

            // if (!this.status_selected ) 
            // {       
            //   this.$toast.add({
            //       severity: "error",
            //       summary: "error",
            //       detail: "Please Select Status",
            //       life: 3000,
            //   });
            //   return false;
            // }

            var data = {                        
                    name: this.product.name,
                    client_id:localStorage.getItem("client_id"),
                    user_id: localStorage.getItem("id"),
                    status:"Active",
                    // sequence_number:1 ,
                    // _id:"65c5c3cd1679eff443c4eb88", 
            };

            if (this.product._id) 
            {
                data['_id']=this.product._id;
                data['status']=this.status_id;
                this.isLoadingModel=true;
                var promise = apis.updateStatus(data);
                promise.then((response) => {
                    this.isLoadingModel=false;
                    this.productDialog=false;
                    this.$swal(response.data.message);
                    this.get_count();
                    this.get_list();
                });
        
            } 
            else
            {
                this.isLoadingModel=true;
                var promise = apis.addTicketStatus(data);
                promise.then((response) => {
                    this.isLoadingModel=false;
                    this.productDialog=false;
                    this.$swal(response.data.message);
                    this.get_count();
                    this.get_list();
                });
            }

           
        },
        async handleFileUpload1(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                  this.product.logo="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                 
                   
                });
            });

        },
        async handleFileUpload2(event)
        {
            var fileUp  =   event.files[0];
            var file    =   fileUp;
            this.file   =   file;
            var prfeix= await this.date_time_prefix();
            var filename = prfeix+"_"+file.name;
            this.file_data = 
            {
                filePath:filename,
                contentType: file.type,
            };

            var promise = apis.upload_to_AWS(this.file_data);
                promise.then((response) => {
        
                axios
                .put(response.data.data, file, {
                    headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": file.type,
                    },
                })
                .then((response) => {
                    this.product.fevicon="https://eticket-docs.s3.ap-south-1.amazonaws.com/" +filename;
                
                   
                });
            });

        },
      
      async search(count) {
        if (count == 2) {
          this.search_filter="";
          this.client_selected="";
            this.clientid="";
        }
        await this.get_list();
        await this.get_count();
      },
      async onPage(event) {
        this.lazyParams = event;
        this.page_no = event.page + 1;
        this.limit = event.rows;
        // console.log(event);
        //  await this.get_count();
        if (this.totalRecords > 0) {
          this.get_list();
        }
      },
      get_count: function () {
        this.clientid = "";
      this.clientid = this.client_selected._id;
      
      if (this.clientid=='' || this.clientid==undefined) 
      {
        this.clientid=localStorage.getItem("client_id");
      }
        var data = {
          count: true,
          client_id:this.clientid,
          search:this.search_filter
        };
        this.loading = true;
        var promise = apis.getTicketStatusList(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.totalRecords = response.data.data;
          //alert(this.totalRecords);
        });
        console.log(data);
      },
      get_list: function () {
        this.clientid = "";
      this.clientid = this.client_selected._id;
      
      if (this.clientid=='' || this.clientid==undefined) 
      {
        this.clientid=localStorage.getItem("client_id");
      }

        var data = {
          limit: this.limit,
          page_no: this.page_no,      ///////
          count: false,
          client_id: this.clientid,   ///////
          search:this.search_filter
        };
        this.loading = true;
        var promise = apis.getTicketStatusList(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        console.log(data);
      },
      async date_time_prefix() 
        {
            // Get the current date and time
            const currentDateTime = new Date();

            // Format the date as YYYY_MM_DD
            const formattedDate = currentDateTime.toISOString().split('T')[0].replace(/-/g, '_');

            // Format the time as HH_mm_ss
            const formattedTime = currentDateTime.toTimeString().split(' ')[0].replace(/:/g, '_');

            // Construct the final string
            const resultString = `${formattedDate}_${formattedTime}`;

            return resultString;
        },
      openNew() {
        this.product = {};
        this.submitted = false;
        this.status_selected={ name: "Active", value: "Active" };
        this.productDialog = true;
      },
      async editProduct(product) {
        this.file_attachment = "";
        this.product = { ...product };
        if (this.product.status) 
        {
            this.status_selected={ name: this.product.status, value:  this.product.status };
        }
       
        this.productDialog = true;
      },
      confirmDeleteProduct(product) {
        this.product = product;
        this.product.remark='';
        this.deleteProductDialog = true;
      },
      deletestatus() {
        if (this.product.remark==null || this.product.remark=="" || this.product.remark==undefined) 
        {
          this.$toast.add({
              severity: "error",
              summary: "error",
              detail: "Please Enter Remark",
              life: 3000,
          });
          return false;
        }
        
        if (this.product._id) {
          var data = {
            "client_id": localStorage.getItem("client_id"),
            "_id":this.product._id,
            "user_id":localStorage.getItem("id"),
            "remark":this.product.remark,
          };
          this.isLoadingModel = true;
          var promise = apis.deleteTicketStatus(data);
          promise.then((responseapi) => {
            this.isLoadingModel = false;
            if (responseapi.data.status == true) {
              // this.hideDialog1();
              this.$swal(responseapi.data.message);
              this.deleteProductDialog = false;
              
              this.get_list();
              this.get_count();
            } else {
              this.$swal("error to Delete Client");
            }
          });
        }
      },
      async exportCSV() {
        this.limit = this.totalRecords;
        this.get_list();
        await this.$refs.dt.exportCSV();
      },
      initFilters() {
        this.filters = {
          global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        };
      },
    },
    components: {  },
  };
  </script>
    <style scoped lang="scss">
  @import "../assets/demo/badges.scss";
  </style>
    